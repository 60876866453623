.card {
  width: 100%;
  height: 100%;
  border-radius: 1px;
  border: 1px solid #f3b61f;
  box-shadow: 1px 1px 2px #f3b61f;
  transition: 0.3s;
  transform-style: preserve-3d;
  position: relative;
  cursor: pointer;
  background-color: #302509;

  img {
    width: 99%;
    height: 99%;
  }

  .card-face {
    backface-visibility: hidden;
    position: absolute;
    width: 100%;
    height: 100%;
    &.card-back-face {
      transform: rotateY(180deg);
    }
  }

  &.is-flipped {
    transform: rotateY(180deg);
  }

  &.is-inactive {
    // visibility: hidden;
    opacity: 0;
  }
}
