.bold {
  font-weight: 600;
  text-transform: uppercase;
}

.App {
  position: absolute;
  width: 100%;
  height: 100%;

  header {
    position: relative;
    width: calc(100% - 2rem);
    text-align: center;
    margin: 1rem;

    > div {
      font-size: 1.1rem;
      text-align: center;
      margin: 1rem;
    }

    > img {
      height: 10vh;
    }
  }

  footer {
    width: 360px;
    position: relative;
    margin: 0 auto;
    padding: 10px 4px;
    margin-top: 10px;

    .score {
      justify-content: center;
      display: flex;

      div {
        padding: 8px;
      }
    }

    .restart {
      display: flex;
      justify-content: center;
    }

    .MuiButton-containedPrimary {
      color: antiquewhite;
      background-color: black;
    }
    .MuiButton-contained:hover {
      background-color: #666666;
    }
  }

  .container {
    border: 4px solid #f3b61f;
    padding: 12px;
    box-shadow: 5px 5px 8px #462d19, 6px 6px 4px #f3b61f;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-template-rows: repeat(4, 1fr);
    justify-items: center;
    align-items: stretch;
    gap: 1rem;
    margin: 0 auto;
    width: 900px;
    height: 400px;
    perspective: 100%;
    max-width: 720px;
    background-color: #462d19;
  }

  .loader {
    position: fixed;
    top: 50%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    background: rgba(70, 45, 25, 0);
    text-align: center;
    font-size: xx-large;
    font-weight: 600;
  }

  .credits {
    position: fixed;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    font-size: small;
    bottom: 5px;
  }
}
